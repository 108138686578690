<template>
  <v-card flat>
    <v-card-text>
      <div class="xgrid xgrid-cols-2 xgap-[1em]">
        <v-card outlined flat class="xshadow-lg" color="primary">
          <v-card-title class="xfont-bold xtext-gray-600 light--text">
            Paying Members
          </v-card-title>
          <v-card-subtitle class="xfont-bold xtext-[13px] light--text">
            Members that pays for clocking
          </v-card-subtitle>
          <v-card-text>
            <avatars v-if="workspace" :count="10" :users="payings" />
          </v-card-text>
        </v-card>
        <v-card
          outlined
          flat
          class="xshadow-lg"
          v-for="role in workspaceRoles"
          :key="role.id"
        >
          <v-card-title class="xfont-bold xtext-gray-600">
            {{ role.normalize_name }}
          </v-card-title>
          <v-card-subtitle class="xfont-bold xtext-[13px]">
            {{ role.description }}
          </v-card-subtitle>
          <v-card-text>
            <avatars
              v-if="workspace"
              :count="15"
              :users="getUsersByRole(role)"
            />
          </v-card-text>
        </v-card>
      </div>
      <app-table :loading="false" :headings="headers" :items="users" class="xmt-[1em]">
        <template v-slot:name="{ item }">
          <avatar size="35" :user="item" with-name></avatar>
        </template>
        <template v-slot:id="{ item }">
          <clipboard :text="item.id"></clipboard>
        </template>
        <template v-slot:roles="{ item }">
          <v-chip
            small
            label class="xmr-[0.5em]"
            v-for="(role, index2) in item.roles"
            :key="index2"
          >
            {{ role.normalize_name }}
          </v-chip>
        </template>
      </app-table>
    </v-card-text>
  </v-card>
</template>

<script>
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      headers: [
        { text: "Name", value: "name", sortable: true },
        { text: "ID", value: "id", sortable: true },
        { text: "Email Address", value: "email", sortable: true },
        { text: "Roles", value: "roles", sortable: false },
      ],
    };
  },
  computed: {
    team_id() {
      return this.$route.params.id;
    },
    ...mapGetters("roles", ["workspaceRoles"]),
    ...mapGetters("workspace", ["users", "workspace"]),
    payings() {
      return this.users.filter((i) => i.is_paying);
    },
  },
  created() {
    this.fetchWorkspaceInfo(this.team_id);
    this.fetchWorkspaceRoles(this.team_id);
    this.fetchWorkspaceUsers(this.team_id);
  },
  methods: {
    ...mapActions("roles", ["fetchRoles", "fetchWorkspaceRoles"]),
    ...mapActions("workspace", ["fetchWorkspaceUsers", "fetchWorkspaceInfo"]),
    getUsersByRole(role) {
      return this.users.filter((i) => i.roles.some((j) => j.id === role.id));
    },
  },
};
</script>

<style lang="scss" scoped></style>
